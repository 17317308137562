import { navigate } from 'gatsby';
import React from 'react';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
// styles
import styles from './backdoor.module.scss';

const Backdoor = () => {
  return (
    <Layout>
      <SEO
        title="Personalised vitamins, DNA kits, blood tests & more | Vitl"
        description="Feel 100% more often with filler-free, non-GMO nutritional supplements made from the highest quality vitamins & minerals to keep your body functioning its best."
      />
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.contentCenter}>
            <div className={styles.contentWrapper}>
              <h1>Congratulations!</h1>
              <hr />
              <p>Check you out, smarty pants 🙂</p>
              <p>
                Your reward for finding the backdoor is £5 off your next order - all you need to do is enter{' '}
                <strong>'BACKDOOR'</strong> at checkout to claim your discount
              </p>
              <button className={styles.button} type="button" onClick={() => navigate('/products')}>
                See Products
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Backdoor;
